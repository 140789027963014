import '../css/vendor.scss'

//import * as bootstrap from 'bootstrap'
window.bootstrap = require( 'bootstrap')

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, far, fab)

dom.i2svg()
